.navbar{
  background-color:transparent;
}
.navbar-nav{
  background-color:transparent;
  text-align: left;
  padding-left: 1em;
  color:white;
}
.navbar img{
  width: 50%;
}
@media screen and (max-width: 768px){
  .navbar-nav{
    /* background-color:#fa7995; */
   text-align: left;
    padding-left: 1em;
  }
  
}
iframe{
  border: none;
}